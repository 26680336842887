<template>
  <div class="pView">
    <div class="aTitle">工厂库存端</div>
    <div class="aTiao">
      <div class="lab1">新注册的用户单个报价：</div>
      免费查询
      <a-input class="number"/>
      次，每次展示
      <a-input class="number"/>
      条，展示按
      <a-select class="xz" :options="options" @change="handleChange" ></a-select>
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
    </div>
    <div class="aTiao">
      <div class="lab1">非新注册的用户单个报价：</div>
      免费查询
      <a-input class="number"/>
      次，每次展示
      <a-input class="number"/>
      条，展示按
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
    </div>
    <div class="aTiao">
      <div class="lab2">新注册的用户上传BOM报价：</div>
      免费查询
      <a-input class="number"/>
      个BOM（每个限200条），每个BOM展示
      <a-input class="number"/>
      条，展示按
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
    </div>
    <div class="aTiao">
      <div class="lab2">非新注册的用户上传BOM报价：</div>
      免费查询
      <a-input class="number"/>
      个BOM（每个限200条），每个BOM展示
      <a-input class="number"/>
      条，展示按
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
      <a-select class="xz"></a-select>
    </div>
  </div>
</template>

<script>
import {ref,reactive} from "vue";

export default {
  name : "ask",

  setup() {

    const options = ref([{
      value: 'jack',
      label: 'Jack (100)',
    }, {
      value: 'lucy',
      label: 'Lucy (101)',
    }]);

    const handleChange = value => {
      console.log(value); // { key: "lucy", label: "Lucy (101)" }
    };

    let date = reactive({
      order : [ { "label" : "时间顺序", "value" : "1" }, { "label" : "时间倒序", "value" : "-1" } ],
    });

    return{
      date,
      options,
      handleChange
    }
  }
}


</script>

<style scoped>


.pView { padding: 20px; }
.aTitle { border-bottom: 1px solid #cccccc; font-size: 16px; line-height: 16px; height: 30px; }
.aTiao .lab1 { width: 170px; float: left; text-align: right; }
.aTiao .lab2 { width: 200px; float: left; text-align: right; }
.aTiao { height: 60px; line-height: 60px; }
.pView .number { width: 90px; margin: 0 10px 0 10px; }
.pView .xz { width: 90px; margin: 0 10px 0 10px;  touch-action: none;  }
</style>